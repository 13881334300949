import React, { useState } from 'react'
import styled from 'styled-components'

import { media } from '../utils/style-utils'
import {
  H4,
  P,
  Tiny,
  StyledLink,
  EmailInput,
  FormButton,
} from '../components/shared'

const Banner = styled.div`
  position: relative;
  background-color: ${props => props.theme.colors.emailBanner};
  ${media.small`
    margin: 45px 0 0 0;
    padding: 50px 30px;
    max-width: 100vw;
  `}
  ${media.large`
    margin: 0 auto;
    padding: 70px 6% 13px 6%;
    width: ${props => props.maxWidth || '1026px'};
  `}
`

const Content = styled.div`
  display: flex;
  justify-content: space-between;
  ${media.small`
    flex-direction: column;
    align-items: center;
  `}
  ${media.medium`
    align-items: flex-start;
  `};
  ${media.large`
    flex-direction: row;
    align-items: center;
  `}
`

const TextBox = styled.div`
  ${media.medium`
    max-width: 100%;
    margin-bottom: 30px;
  `}
  ${media.large`
    max-width: 40%;
    margin-right: 60px;
    margin-bottom: 0;
  `}
`

const EmailForm = styled.div`
  display: flex;
  justify-content: space-between;
  text-align: center;
  ${media.small`
    min-width: 100%;
    flex-direction: column;
  `}
  ${media.medium`
    flex-direction: row;
    min-width: 100%;
  `};
  ${media.large`
    max-height: 50px;
    min-width: 55%;
    width: 55%;
  `}
`

const EmailButton = styled(FormButton)`
  ${media.small`
    flex-direction: column;
    width: 100%;
    margin-top: 6px;
  `}
  ${media.medium`
    margin-top: 0;
    margin-left: 10px;
    width: fit-content;
    min-width: 160px;
    height: 54px;
  `}
`
const TermsContainer = styled.div`
  font-size: 9px !important;
`

const Terms = styled(Tiny)`
  colors: ${props => props.theme.colors.dark};
  width: 100%;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  font-size: 8.9px !important;
  ${media.small`
    margin: 34px 0 0 0;
  `};
  ${media.medium`
    margin: 45px 0 0 0;
  `};
`

const Title = styled(H4)`
  font-family: ${props => props.theme.fonts.boldTitle};
  text-transform: uppercase;
  letter-spacing: 0.02em;
  margin-bottom: 16px;
  line-height: 1.3;
  width: 100%;
  ${media.small`
    text-align: center;
  `};
  ${media.medium`
    text-align: ${props => (props.submitted ? 'center' : 'left')};
  `};
`

const Subtitle = styled(P)`
  ${media.small`
    text-align: center;
    margin-bottom: 32px;
  `}
  ${media.medium`
    text-align: left;
    margin-bottom: 0;
    font-size: 14px;
  `}
`

const Link = styled(StyledLink)`
  color: ${props => props.theme.colors.dark};
  font-size: 8.9px !important;
  &:hover {
    font-family: ${props => props.theme.fonts.boldPrimary};
  }
  ${media.small`
    font-family: ${props => props.theme.fonts.boldPrimary};
  `}
  ${media.medium`
    font-family: ${props => props.theme.fonts.primary};
  `}
`

const EmailBanner = ({ children, maxWidth, data: { markdownRemark } }) => {
  const [email, setEmail] = useState('')
  const [submitted, setSubmitted] = useState(false)
  const [error, setError] = useState(false)

  const {
    emailBannerHeading,
    emailBannerSubheading,
  } = markdownRemark.frontmatter

  const validateEmail = value => {
    const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i
    let error
    if (!value) error = 'Required'
    else if (!regex.test(value)) error = 'Please enter a valid email address'
    return error
  }

  const handleChange = e => {
    setError(null)
    setEmail(e.target.value)
  }

  const handleSubmit = () => {
    const error = validateEmail(email)
    if (error) return setError(error)
    setSubmitted(email)
  }

  return (
    <Banner maxWidth={maxWidth}>
      {children}
      <Content>
        <TextBox>
          <Title>{emailBannerHeading}</Title>
          <Subtitle>{emailBannerSubheading}</Subtitle>
        </TextBox>
        <EmailForm>
          {!submitted ? (
            <>
              <EmailInput
                name="email"
                placeholder="your@email.com"
                value={email}
                handleChange={handleChange}
                error={error}
              />
              <EmailButton onClick={handleSubmit}>Sign Up</EmailButton>
            </>
          ) : (
            <Title submitted={submitted}>Thank you!</Title>
          )}
        </EmailForm>
      </Content>
      <TermsContainer>
        <Terms>
          By clicking "sign up," you agree to receive emails from us and accept
          our <Link to="/terms-and-conditions">terms of use</Link> and{' '}
          <Link to="/privacy-policy">privacy policy</Link>.
        </Terms>
      </TermsContainer>
    </Banner>
  )
}

export default EmailBanner
