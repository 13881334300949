import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

import { media } from '../utils/style-utils'
import { TitleGroup, Divider } from '../components/shared'

const Container = styled.div`
  position: relative;
  background-color: ${({ theme }) => theme.colors.popularBackground};
  width: 100%;
  display: grid;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  ${media.small`
    grid-template-columns: 1fr;
    grid-template-rows: 200px auto;
    padding: 19px 0 43px 0;
    margin: 0;
  `};
  ${media.medium`
    grid-gap: 80px;
    padding: 32px 32px 50px 32px;
    grid-template-rows: 300px auto;
    margin: 65px 0;
  `};
  ${media.large`
    grid-template-columns: 1fr 1fr;
    padding: 32px 60px 32px 32px;
    min-height: 450px;
    grid-template-rows: auto;
    box-sizing: border-box;
  `};
`

const ImageWrapper = styled.div`
  margin: 0;
  height: 100%;
  width: 100%;
`

const LinkWrapper = styled(Link)`
  text-decoration: none;
  height: 100%;
  width: 100%;
`

const PopularPostsTemplate = ({ data, children }) => {
  if (!data) return null

  const posts = data.allMarkdownRemark.edges
  const mostRecent = posts && posts[0]
  const selected = mostRecent && mostRecent.node.frontmatter

  return selected ? (
    <>
      <Container>
        <LinkWrapper to={mostRecent.node.fields.slug}>
          <ImageWrapper>
            <GatsbyImage
              image={getImage(selected.previewImage)}
              alt="About us"
              style={{ height: '100%', width: '100%' }}
              imgStyle={{ objectFit: 'cover' }}
            />
          </ImageWrapper>
        </LinkWrapper>
        <TitleGroup
          description="Popular"
          title={selected.title}
          subheading={selected.description}
          cta="Read More"
          postUrl={mostRecent.node.fields.slug}
          textColor={props => props.theme.colors.popularText}
        />
        {children}
      </Container>
      <Divider />
    </>
  ) : null
}

export default PopularPostsTemplate
