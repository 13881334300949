import React from 'react'
import styled from 'styled-components'

import { H4 } from './shared'

import Facebook from '../img/social/facebook.png'
import Whatsapp from '../img/social/whatsapp.png'
import Twitter from '../img/social/twitter.png'
import Linkedin from '../img/social/linkedin.png'

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 15px 0 0 0;
`

const Image = styled.img`
  height: 33px;
  width: 33px;
  margin-right: 18px;
  cursor: pointer;
`

const Text = styled(H4)`
  font-family: ${props => props.theme.fonts.boldTitle};
  color: ${props => props.theme.colors.socialShare};
  text-transform: uppercase;
  letter-spacing: 0.02em;
  margin-top: 54px;
`

const SocialShare = () => {
  const openWindow = (url, params) => {
    const queryString = Object.keys(params)
      .map(key => `${key}=${encodeURIComponent(params[key])}`)
      .join('&')

    const fullUrl = `${url}?${queryString}`

    window.open(
      fullUrl,
      null,
      'width=600, height=400, location=0, menubar=0, resizeable=0, scrollbars=0, status=0, titlebar=0, toolbar=0'
    )
  }

  const shareViaFacebook = () => {
    openWindow('https://www.facebook.com/sharer/sharer.php', {
      u: `localhost:8000/?type=fb`,
      'og:title': 'PLACEHOLDER',
      'og:description': `Facebook placeholder`,
    })
  }

  const shareViaWhatsapp = () => {
    openWindow('https://api.whatsapp.com/send', {
      url: `localhost:8000/?type=whatsapp`,
      text: `Whatsapp Placeholder`,
    })
  }

  const shareViaTwitter = () => {
    openWindow('https://twitter.com/share', {
      url: `localhost:8000/?type=twitter`,
      text: `Twitter Placeholder`,
    })
  }

  const shareViaLinkedIn = () => {
    openWindow('https://www.linkedin.com/share', {
      url: `localhost:8000/?type=linkedIn`,
      text: `Placeholder`,
    })
  }

  return (
    <>
      <Text>Share</Text>
      <ButtonContainer>
        <Image src={Facebook} alt="Facebook logo" onClick={shareViaFacebook} />
        <Image src={Whatsapp} alt="Whatsapp logo" onClick={shareViaWhatsapp} />
        <Image src={Twitter} alt="Twitter logo" onClick={shareViaTwitter} />
        <Image src={Linkedin} alt="LinkedIn logo" onClick={shareViaLinkedIn} />
      </ButtonContainer>
    </>
  )
}

export default SocialShare
