import React from 'react'
import styled from 'styled-components'
import { Tiny } from '../text'

const Wrapper = styled.div`
  width: 100%;
`

const Input = styled.input`
  background-color: ${({ theme }) => theme.colors.light};
  border: 2px solid ${({ theme, error }) => error ? 'red' : theme.colors.light};
  border-radius: 100px;
  padding: 15px 20px;
  width: 100%;
  line-height: 1;
  &:focus {
    outline: none;
    border: 2px solid ${({ theme }) => theme.colors.dark};
  }
  ::placeholder,
  ::-webkit-input-placeholder {
    color: ${({ theme }) => theme.colors.socialShare};
  }
  :-ms-input-placeholder {
    color: ${({ theme }) => theme.colors.socialShare};
  }
`

const ErrorText = styled(Tiny)`
  font-family: ${props => props.theme.fonts.boldPrimary};
  width: 100%;
  text-align: left;
  color: red;
  margin-left: 24px;
  height: 20px;
`

const EmailInput = props => {
  const { name, value, placeholder, handleChange, error } = props
  return (
    <Wrapper>
      <Input
        name={name}
        type="text"
        placeholder={placeholder}
        value={value}
        onChange={handleChange}
        error={error}
      />
      <ErrorText>{error}</ErrorText>
    </Wrapper>
  )
}

export default EmailInput
