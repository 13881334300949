import React from 'react'
import styled from 'styled-components'

import { media } from '../utils/style-utils'
import { H5 } from './shared'

const Body = styled.div`
  width: 100%;
  display: grid;
  grid-gap: 13px;
  ${media.medium`
    grid-template-columns: 1fr 1fr;
    grid-gap: 50px;
    margin-top: 20px;
    margin-bottom: 45px;
    padding: 0 30px;
  `}
  ${media.large`
    display: inline;
    margin-bottom: 55px;
    margin-top: 0;
    padding: 0;
  `}
`

const Row = styled.div`
  background-color: ${props => props.theme.colors.light};
  border: 1px solid ${props => props.theme.colors.border};
  width: 100%;
  display: grid;
  align-items: center;
  grid-template-columns: 138px 1fr 1fr 1fr 1fr;
  min-height: 238px;
  padding: 39px 0;
`

const Header = styled(Row)`
  background-color: ${props => props.theme.colors.tableHeader};
  padding: 0;
  min-height: 42px;
  border: none;
  display: none;
  ${media.large`
    display: grid;
    margin: 34px 0 16px 0;
    grid-template-columns: ${props => props.tableSpacing};
  `};
`

const HeaderText = styled(H5)`
  font-family: ${props => props.theme.fonts.boldTitle};
  color: ${props => props.theme.colors.light};
  text-transform: uppercase;
  ${media.large`
    margin-left: 28px;
  `};
`

const Table = ({ TableRow, productsTable, tableSpacing, amazonButtons }) =>
  productsTable && (
    <Body>
      <Header tableSpacing={tableSpacing}>
        <HeaderText />
        {!amazonButtons && <HeaderText />}
        <HeaderText>Key Takeaway</HeaderText>
        <HeaderText>Pros</HeaderText>
        <HeaderText>Cons</HeaderText>
        {amazonButtons && <HeaderText />}
      </Header>
      {productsTable.map(product => (
        <TableRow
          key={product.productName}
          product={product}
          tableSpacing={tableSpacing}
          amazonButtons={amazonButtons}
        />
      ))}
    </Body>
  )

export default Table
