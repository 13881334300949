import React from 'react'
import styled from 'styled-components'

const SearchContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  color: #000;
  border-bottom: 1px solid #000;
  margin-left: 26px;
  width: 200px;
`

const Input = styled.input`
  background-color: #fff;
  padding-bottom: 6px;
  padding-top: 9px;
  border: none;
  font-weight: 500;
  font-size: 16px;
  text-align: right;
  &:focus {
    outline: none;
  }
  ::placeholder,
  ::-webkit-input-placeholder {
    color: ##7b7b7b;
  }
  :-ms-input-placeholder {
    color: ##7b7b7b;
  }
`

const SearchIcon = styled.i`
  font-size: 16px;
  padding: 0 3px 0 8px;
  margin-bottom: -4px;
`

const SearchInput = props => {
  const { value } = props
  return (
    <SearchContainer>
      <Input type="text" placeholder="Search" value={value} />
      <SearchIcon className="fa fa-search" />
    </SearchContainer>
  )
}

export default SearchInput
