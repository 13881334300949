import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

import { media } from '../utils/style-utils'
import { H2, HomeLink } from '../components/shared'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  ${media.medium`
    max-width: 1367px;
  `}
`

const ImageWrapper = styled.div`
  background-color: ${props => props.theme.colors.body};
  min-height: 320px;
  ${media.small`
    width: 100vw;
  `}
  ${media.medium`
    width: 100%;
  `}
`

const ContentContainer = styled.div`
  font-family: Larsseit, sans-serif;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  flex: 1;
  ${media.small`
    padding: 0 20px;
  `}
  ${media.medium`
    max-width: 1026px;
    padding: 0;
  `}
`

const Title = styled(H2)`
  font-family: ${props => props.theme.fonts.boldTitle};
  letter-spacing: 0.02em;
  margin: 75px 0 40px 0;
`

const Home = styled.div`
  ${media.small`
    margin: 30px 0;
  `}
  ${media.medium`
    margin: 55px 0 60px 0;
  `}
`

export const AboutPageTemplate = ({ EmailBanner, content, image, contentComponent }) => {
  const PageContent = contentComponent || content
  return (
    <Container>
      {image && (
        <ImageWrapper>
          <GatsbyImage
            image={getImage(image)}
            alt="About us"
            style={{ height: '100%', width: '100%' }}
            imgStyle={{ objectFit: 'cover' }}
          />
        </ImageWrapper>
      )}
      <ContentContainer>
        <Title>About Us</Title>
        <PageContent className="content" content={content} />
        <Home>
          <HomeLink />
        </Home>
        <EmailBanner />
      </ContentContainer>
    </Container>
  )
}

AboutPageTemplate.propTypes = {
  content: PropTypes.string.isRequired,
  contentComponent: PropTypes.func.isRequired,
  image: PropTypes.object.isRequired,
}

export default AboutPageTemplate
