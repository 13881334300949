const theme = {
  colors: {
    body: '#FAFAFA',
    dark: '#000000',
    light: '#ffffff',
    navActive: '#2EABE1;', 
    popularBackground: '#D7A421',
    popularText: '#ffffff',
    emailBanner: '#B5E9FF',
    topRated: '#D7A421',
    bestValue: '#1A76D2',
    tableHeader: '#2EABE1',
    socialShare: '#A3A2A2',
    blogDate: '#9E9E9E',
    blogPreview: '#7B7B7B',
    border: '#E5E5E5',
    footer: '#000000',
  },
  fonts: {
    title: "'Brandon', Helvetica, sans-serif",
    boldTitle: "'Brandon-Black', Helvetica, sans-serif",
    primary: "'Larsseit', sans-serif",
    boldPrimary: "'Larsseit-Bold', sans-serif",
  },
}

export default theme
