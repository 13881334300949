import styled from 'styled-components'
import {media} from '../../utils/style-utils'

export const Button = styled.button`
  font-family: ${props => props.theme.fonts.boldTitle};
  background-color: ${props => props.theme.colors.light};
  border: 2px solid ${props => props.theme.colors.light};
  color: ${props => props.theme.colors.dark};
  border-radius: 100px;
  cursor: pointer;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  line-height: 1;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    border: 2px solid ${props => props.theme.colors.dark};
    background-color: ${props => props.theme.colors.dark};
    color: ${props => props.theme.colors.light};
  }
  ${media.small`
    margin-top: 20px;
    padding: 16px 42px;
  `}
  ${media.medium`
    margin-top: 0;
    padding: 12px 42px;
  `}
`

export const TransparentButton = styled(Button)`
  &:hover {
    border: 2px solid ${props => props.theme.colors.light};
    background-color: transparent;
  }
`

export const FormButton = styled(Button)`
  background-color: ${props => props.theme.colors.dark};
  border: 2px solid ${props => props.theme.colors.dark};
  color: ${props => props.theme.colors.light};
  &:hover {
    background-color: ${props => props.theme.colors.light};
    color: ${props => props.theme.colors.dark};
  }
`

export const GreenButton = styled(Button)`
  background-color: #02BB8E;
  border-radius: 100px;
  border: none;
  &:hover {
    background-color: #2EABE1;
    border: none;
  }
`