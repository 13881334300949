import React from 'react'
import styled from 'styled-components'
import { getImage } from 'gatsby-plugin-image'

import { media } from '../../utils/style-utils'
import BlogCard from './BlogCard'

const Container = styled.div`
  width: 100%;
  display: grid;
  ${media.small`
    grid-row-gap: 33px;
    grid-template-columns: 1fr;
    padding: 0 24px;
  `};
  ${media.medium`
    grid-gap: 18px;
    grid-template-columns: 1fr 1fr;
    padding: 0;
  `};
  ${media.large`
    grid-template-columns: 1fr 1fr 1fr 1fr;
    padding: 0;
  `};
`

const BlogRoll = ({ posts }) => (
  <Container>
    {posts &&
      posts.slice(0, 4).map(({ frontmatter, fields }) => {
        const image = getImage(frontmatter.previewImage)

        return (
          <BlogCard
            key={frontmatter.title}
            title={frontmatter.title}
            image={image}
            shortDescription={frontmatter.description}
            slug={fields.slug}
          />
        )
      })}
  </Container>
)

export default BlogRoll
