import React from 'react'
import styled from 'styled-components'
import { Label } from '../text'

const FormLabel = styled(Label)`
  font-family: ${props => props.theme.fonts.title};
  font-weight: 600;
`

const Input = styled.input`
  background-color: transparent;
  border: 1px solid ${props => props.theme.colors.dark};
  box-sizing: border-box;
  border-radius: 4px;
  width: 100%;
  height: 48px;
  margin-bottom: 30px;
  padding: 0 20px;
  &:focus {
    outline: none;
  }
`

const TextArea = styled.textarea`
  background-color: transparent;
  min-height: 173px;
  border: 1px solid ${props => props.theme.colors.dark};
  box-sizing: border-box;
  border-radius: 4px;
  width: 100%;
  padding: 20px;
  &:focus {
    outline: none;
  }
`

export const FormInput = props => {
  const { label, name, value, placeholder, type = 'text' } = props
  return (
    <>
      {label && <FormLabel htmlFor={name}>{label}</FormLabel>}
      <Input type={type} name={name} placeholder={placeholder} value={value} />
    </>
  )
}

export const TextAreaInput = props => {
  const { label, name, value, placeholder } = props
  return (
    <>
      {label && <FormLabel htmlFor={name}>{label}</FormLabel>}
      <TextArea
        type="textarea"
        name={name}
        placeholder={placeholder}
        value={value}
      />
    </>
  )
}
