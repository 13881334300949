import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'

import { StyledLink } from '../shared'
import { media } from '../../utils/style-utils'

const Container = styled.nav`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  background-color: ${props => props.theme.colors.body};
  z-index: 2;
  box-shadow: ${({ hasScrolled }) =>
    hasScrolled ? '0 2px 4px rgba(0, 0, 0, 0.1)' : 'none'};
  width: 100%;
  max-width: 100vw;
  transition: box-shadow 0.2s ease-in-out;
  ${media.small`
    height: 99px;
  `};
  ${media.medium`
    height: 146px;
  `};
`

const InteriorContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;
  ${media.small`
    width: 100%;
    max-width: 100vw;
    justify-content: space-between;
  `}
  ${media.medium`
    justify-content: flex-end;
    width: 90%;
    max-width: 1300px;
  `}
`

const NavGroup = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
`

const NavLinks = styled(NavGroup)`
  justify-content: flex-end;
  ${media.small`
    max-width: 50%;
    margin-right: 16px;
  `}
  ${media.medium`
    max-width: 250px;
    margin-right: 0;
  `}
`

const NavLink = styled(StyledLink)`
  color: ${({ selected, theme }) =>
    selected ? theme.colors.navActive : theme.colors.dark};
  font-family: ${props =>
    props.selected ? props.theme.fonts.boldPrimary : props.theme.fonts.primary};
  text-align: center;
  text-decoration: none;
  ${media.small`
    width: 80px;
  `}
  ${media.medium`
    width: 100px;
  `}
  &:hover {
    font-family: ${props => props.theme.fonts.boldPrimary};
  }
`

const Logo = styled.img`
  width: auto;
  ${media.small`
    height: 46px;
    margin: 8px 0 0 20px;
  `};
  ${media.medium`
    margin: 0;
    height: 80px;
  `};
`

const LogoWrapper = styled.div`
  ${media.medium`
    position: absolute;
    transform: ${props =>
      props.centered ? 'translate(-50%, -50%)' : 'translate(0%, -50%)'};
    top: 50%;
    left: ${props => (props.centered ? '50%' : 0)};
  `}
`

const Navbar = ({ logo, hasScrolled, centered }) => {
  // const {
  //   allMarkdownRemark: { group: categories },
  // } = data

  let viewingAbout = false
  let viewingContact = false
  if (typeof window !== `undefined`) {
    viewingAbout = window.location.pathname === '/about'
    viewingContact = window.location.pathname === '/contact'
  }
  return (
    <Container
      role="navigation"
      aria-label="main-navigation"
      hasScrolled={hasScrolled}
    >
      <InteriorContainer>
        <LogoWrapper centered={centered}>
          <Link to="/">
            <Logo src={logo} alt={`site logo`} />
          </Link>
        </LogoWrapper>
        <NavLinks>
          {/* <CategoriesDropdown categories={categories} /> */}
          <NavLink to="/about" selected={viewingAbout}>
            About
          </NavLink>
          <NavLink to="/contact" selected={viewingContact}>
            Contact
          </NavLink>
        </NavLinks>
      </InteriorContainer>
    </Container>
  )
}

export default Navbar
