import React from 'react'
import styled from 'styled-components'
import { StyledLink } from '../../components/shared/text'

const Link = styled(StyledLink)`
  color: ${props => props.theme.colors.dark};
  font-family: ${props => props.theme.fonts.boldTitle};
  text-transform: uppercase;
  width: fit-content;
  margin: 55px 0 45px 0;
`

const HomeLink = () => <Link to="/">⬅ Back Home</Link>

export default HomeLink
