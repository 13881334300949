import styled from 'styled-components'
import { Link } from 'gatsby'

import { media } from '../../utils/style-utils'

export const H1 = styled.h1`
  line-height: 1;
  font-weight: normal;
  margin: 0;
  ${media.small`
    font-size: 24px;
  `}
  ${media.medium`
    font-size: 36px;
    letter-spacing: 0.02em;
  `}
`

export const H2 = styled.h2`
  line-height: 1;
  letter-spacing: 0.25em;
  text-transform: uppercase;
  font-weight: normal;
  margin: 0;
  ${media.small`
    font-size: 20px;
  `}
  ${media.medium`
    font-size: 30px;
  `}
`

export const H3 = styled.h2`
  font-size: 24px;
  line-height: 1;
  letter-spacing: 0.25em;
  text-transform: uppercase;
  font-weight: normal;
  margin: 0;
`

export const H4 = styled.h4`
  font-size: 18px;
  line-height: 1;
  letter-spacing: 0.02em;
  font-weight: normal;
  margin: 0;
`

export const H5 = styled.h5`
  font-size: 16px;
  font-weight: normal;
  line-height: 1.625;
  letter-spacing: 0.02em;
  font-weight: normal;
  margin: 0;
`

export const P = styled.p`
  font-family: Larsseit, sans-serif;
  line-height: 1.425;
  letter-spacing: 0.02em;
  font-weight: normal;
  margin: 0;
  ${media.small`
    font-size: 14px;
  `}
  ${media.medium`
    font-size: 16px;
  `}
`

export const Tiny = styled.p`
  font-family: Larsseit, sans-serif;
  font-size: 9px !important;
  font-weight: normal;
  margin: 0;
`

export const StyledLink = styled(Link)`
  line-height: 1;
  text-decoration: underline;
  font-weight: normal;
  margin: 0;
`

export const Label = styled.label`
  font-size: 14px;
  line-height: 1.425;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  margin: 0;
`
