import React from 'react'
import styled from 'styled-components'

import { BlogRoll, Divider } from '../components/shared'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 65px 0;
`

const OtherPostsTemplate = ({ data }) => {
  if (!data.allMarkdownRemark.edges.length) return null
  const posts = data.allMarkdownRemark.edges.map(post => post.node)

  return posts.length ? (
    <>
      <Container>
        <BlogRoll posts={posts} />
      </Container>
      <Divider />
    </>
  ) : null
}

export default OtherPostsTemplate
