import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

import { media } from '../utils/style-utils'
import { TitleGroup } from '../components/shared'

const JumboImage = styled.div`
  position: relative;
  overflow: hidden;

  display: grid;
  place-items: start;
  box-sizing: border-box;

  ${media.small`
    width: 100vw;
    height: 437px;
  `}
  ${media.medium`
    width: 100%;
  `};
  ${media.large`
    height: 713px;
    width: calc(100% + 341px);
    max-width: 1303px !important; 
  `}
`

const TextBox = styled.div`
  width: 100%;
  height: 100%;

  ${media.small`
    height: 437px;
    padding-bottom: 39px;
  `}
  ${media.large`
    padding-left: 275px;
    padding-bottom: 132px;
    max-width: 1303px !important; 
    margin: 0 auto;
  `}
`

const StyledLink = styled(Link)`
  position: absolute;
  top: 0;
  left: 0;

  height: 100%;
  width: 100%;

  background-color: rgba(0, 0, 0, 0.5);

  text-decoration: none;

  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  box-sizing: border-box;
`

const JumbotronTemplate = ({ data }) => {
  const {
    frontmatter: post,
    fields: { slug },
  } = data.allMarkdownRemark.edges[0].node

  return (
    <JumboImage>
      <GatsbyImage
        image={getImage(post.featuredImage)}
        alt="dog"
        style={{ height: '100%', width: '100%' }}
        imgStyle={{ objectFit: 'cover' }}
      />
      <StyledLink to={slug}>
        <TextBox>
          <TitleGroup
            description="Featured"
            title={post.title}
            subheading={post.description}
            cta="Read More"
            postUrl={slug}
          />
        </TextBox>
      </StyledLink>
    </JumboImage>
  )
}

export default JumbotronTemplate
