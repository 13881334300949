import React from 'react'
import styled from 'styled-components'
import { media } from '../../utils/style-utils'

const DividerLine = styled.div`
  width: 100%;
  max-width: ${props => props.maxWidth || '100%'};
  height: 1.5px;
  background-color: ${props => props.theme.colors.border};
  margin: 0 auto;
  display: none;
  ${media.medium`
    display: block;
  `};
`

const Divider = ({ maxWidth }) => <DividerLine maxWidth={maxWidth} />

export default Divider
