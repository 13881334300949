import React from 'react'
import styled from 'styled-components'

import { media } from '../utils/style-utils'
import { P, StyledLink } from './shared/text'

const Container = styled.footer`
  background-color: ${(props) => props.theme.colors.footer};
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  ${media.small`
    padding-top: 19px;
    margin-top: 16px;
    flex-direction: column;
  `}
  ${media.medium`
    padding-top: 19px;
    margin-top: 50px;
  `}
  ${media.large`
    padding-top: 0;
    flex-direction: row;
    margin-top: 92px;
    height: 56px;
  `}
`

const Link = styled(StyledLink)`
  font-family: ${(props) => props.theme.fonts.boldTitle};
  color: ${(props) => props.theme.colors.light};
  font-size: 12px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  text-decoration: none;
  ${media.small`
    margin: 10px 0;
  `}
  ${media.large`
    margin: 0 26px;
  `}
`

const Copyright = styled(P)`
  color: ${(props) => props.theme.colors.light};
  font-size: 11.9px !important;
  line-height: 15px;
  display: flex;
  align-items: center;
  ${media.small`
    margin: 29px 0 19px 0;
  `}
  ${media.large`
    position: absolute;
    right: 0;
    margin: 0 32px 0 0;
  `}
`

const Icon = styled.span`
  font-size: 20px;
  margin-bottom: -6px !important;
  margin-right: 5px;
`

const Footer = ({ title }) => {
  const d = new Date()
  const copyrightYear = d.getFullYear()

  return (
    <Container>
      <Link to="/about">About Us</Link>
      <Link to="/contact">Contact</Link>
      <Link to="/privacy-policy">Privacy Policy</Link>
      <Link to="/terms-and-conditions">Terms & Conditions</Link>
      <Copyright>
        <Icon>©</Icon> 2008-{copyrightYear} {title}. All Rights Reserved.
      </Copyright>
    </Container>
  )
}

export default Footer
