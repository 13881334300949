import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { withPrefix } from 'gatsby'
import styled, { ThemeProvider } from 'styled-components'

import { Footer } from '../../../shared'
import { media } from '../../../shared/utils/style-utils'
import useSiteMetadata from './SiteMetadata'
import Navbar from './Navbar'

import theme from '../utils/theme'
import logo from '../img/logo-with-text.png'

const Layout = styled.div`
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  background-color: ${props => props.bg};
`

const Body = styled.div`
  width: 100%;
  flex: 1;
  display: flex;
  justify-content: center;
  ${media.small`
    margin-top: 99px;
  `};
  ${media.medium`
    padding: 0 32px;
    max-width: 1367px;
    margin-top: 146px;
  `};
`

const TemplateWrapper = ({ children }) => {
  const { title, description } = useSiteMetadata()
  const [scrollPos, setScrollPos] = useState(0)

  useEffect(() => {
    const onScroll = () => setScrollPos(window.pageYOffset)
    window.addEventListener('scroll', onScroll)
    return () => window.removeEventListener('scroll', onScroll)
  }, [])

  return (
    <Layout bg={theme.colors.body}>
      <Helmet>
        <html lang="en" />
        <title>{title}</title>
        <meta name="description" content={description} />

        <link
          href="//maxcdn.bootstrapcdn.com/font-awesome/4.1.0/css/font-awesome.min.css"
          rel="stylesheet"
        />

        <meta name="theme-color" content="#fff" />

        <meta property="og:type" content="business.business" />
        <meta property="og:title" content={title} />
        <meta property="og:url" content="/" />
        <meta property="og:image" content={`${withPrefix('/')}img/logo.png`} />
      </Helmet>
      <ThemeProvider theme={theme}>
        <Navbar logo={logo} hasScrolled={scrollPos > 0} />
        <Body>{children}</Body>
        <Footer title={title} />
      </ThemeProvider>
    </Layout>
  )
}

export default TemplateWrapper
