import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

import { media } from '../utils/style-utils'
import {
  Divider,
  HomeLink,
  FormInput,
  TextAreaInput,
  FormButton,
  H2,
  P,
} from '../components/shared'

const Content = styled.div`
  display: flex;
  flex-direction: column;
  ${media.small`
    padding: 0 20px;
  `};
  ${media.medium`
    padding: 0 ;
  `};
`

const Container = styled.div`
  width: 100%;
  display: flex;
  max-width: 1100px;
`

const Form = styled.form`
  ${media.medium`
    width: 60%;
    padding: 0 64px 0 0;
  `};
`

const Title = styled(H2)`
  font-family: ${props => props.theme.fonts.boldTitle};
  letter-spacing: 0.02em;
  text-transform: uppercase;
  margin-top: 40px;
`

const Subtitle = styled(P)`
  font-size: 16px;
  letter-spacing: 0.02em;
  line-height: 1.875;
  margin: 17px 0 30px 0;
`

const ImageWrapper = styled.div`
  background-position: center;
  background-size: cover;
  margin: 85px 0 0 0;
  width: 50%;
  height: auto;
  max-height: 640px;
  ${media.small`
    display: none;
  `};
  ${media.medium`
    display: inline;
  `};
`

const CTA = styled(FormButton)`
  width: fit-content;
  padding: 14px 77px;
  ${media.small`
    width: 100%;
    margin: 20px 0 0 0;
  `};
  ${media.medium`
    width: fit-content;
    margin: 40px 0 0 0;
  `};
`

const EmailBannerContainer = styled.div`
  margin-top: 65px;
`

const ContactPageTemplate = props => {
  const { EmailBanner, title, subtitle, image } = props
  return (
    <Content>
      <Container>
        <Form name="contact" method="post" data-netlify="true" data-netlify-honeypot="bot-field">
          <Title>{title}</Title>
          <Subtitle>{subtitle}</Subtitle>
          <input type="hidden" name="form-name" value="contact" />
          <FormInput label="Your Name" name="name" />
          <FormInput label="Your E-mail Address" name="email" type="email" />
          <FormInput label="Subject" name="subject" />
          <TextAreaInput label="Your Message" name="message" />
          <CTA type="submit">Send</CTA>
        </Form>
        <ImageWrapper>
          <GatsbyImage
            image={getImage(image)}
            alt="Contact us"
            style={{ height: '100%', width: '100%' }}
            imgStyle={{ objectFit: 'cover' }}
          />
        </ImageWrapper>
      </Container>
      <HomeLink />
      <Divider />
      <EmailBannerContainer>
        <EmailBanner />
      </EmailBannerContainer>
    </Content>
  )
}

ContactPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  image: PropTypes.object.isRequired,
}

export default ContactPageTemplate
