import React from 'react'
import styled from 'styled-components'

import { media } from '../utils/style-utils'
import { H2, BlogRoll } from '../components/shared'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 87px;
`

const Title = styled(H2)`
  font-family: ${({ theme }) => theme.fonts.boldTitle};
  line-height: 1.42;
  ${media.small`
    margin: 40px auto 24px auto;
  `}
  ${media.medium`
    margin: 48px auto;
  `}
`

const TrendingPostsTemplate = ({ data }) => {
  const posts = data.allMarkdownRemark.edges.map(post => post.node)

  return posts.length ? (
    <Container>
      <Title>Trending</Title>
      <BlogRoll posts={posts} />
    </Container>
  ) : null
}

export default TrendingPostsTemplate
