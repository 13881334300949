import React from 'react'
import styled from 'styled-components'

import { media } from '../utils/style-utils'
import { HTMLContent } from  '../components'
import {  HomeLink, H1 } from  '../components/shared'

const Container = styled.div`
  ${media.small`
    padding: 0 20px;
    margin-bottom: 50px;
  `}
  ${media.medium`
    padding: 0;
    max-width: 1026px;
    margin-bottom: 147px;
  `}
`

const Title = styled(H1)`
  font-family: ${props => props.theme.fonts.boldTitle};
  text-transform: uppercase;
  letter-spacing: 0.02em;
  ${media.small`
    margin: 40px 0 20px 0;
  `}
  ${media.medium`
    margin: 40px 0 63px 0;
  `}
`

const Body = styled.div`
  margin-bottom: 69px;
  ${media.small`
    text-align: left;
  `}
  ${media.medium`
    text-align: justify;
  `}
`

const PrivacyPolicyTemplate = ({ data }) => {
  return (
    <Container>
      <Title>Privacy Policy</Title>
      <Body>
        <HTMLContent content={data.markdownRemark.html} />
      </Body>
      <HomeLink />
    </Container>
  )
}

export default PrivacyPolicyTemplate
