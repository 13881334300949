import React, { useEffect } from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { gsap, Power3 } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger.js'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

import { media } from '../../utils/style-utils'
import { H4, P } from './text'
import { Button } from './buttons'

const StyledCard = styled.div`
  width: 100%;
  background: ${props => props.theme.colors.light};
  border: 1px solid ${props => props.theme.colors.border};
  padding-bottom: 65px;
  position: relative;

  overflow: hidden;

  transition: all 0.3s ease-in-out;
`

const ImageWrapper = styled.div`
  width: 100%;
  height: 225px;
  margin: 0;
`

const Content = styled.div`
  padding: 24px 28px 28px 28px;
`

const Title = styled(H4)`
  font-family: ${props => props.theme.fonts.boldPrimary};
  line-height: 1;
  margin-bottom: 15px;
  ${media.small`
    text-align: center;
  `};
  ${media.medium`
    text-align: left;
  `};
`

const Preview = styled(P)`
  color: ${props => props.theme.colors.blogPreview};
  ${media.small`
    text-align: center;
  `};
  ${media.medium`
    text-align: left;
  `};
`

const StyledButton = styled(Button)`
  position: absolute;
  width: fit-content;
  border: 2px solid ${props => props.theme.colors.dark};
  ${media.small`
    min-width: 80%;
    bottom: 24px;
  `};
  ${media.medium`
    min-width: 0;
    bottom: 28px;
  `};
`

const LinkWrapper = styled(Link)`
  text-decoration: none;
  color: ${props => props.theme.colors.dark};

  &:hover {
    text-decoration: underline;
  }
`

const BlogCard = props => {
  const { image, title, shortDescription, slug } = props
  const appendClassName = slug.replace(/[^a-zA-Z0-9 ]/g, '');
  const className = `JumboTextBox--${appendClassName}`
  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger)
    const tl = gsap.timeline({ scrollTrigger: `.${className}` })
    tl.from(`.${className}`, { opacity: 0, y: 40, duration: 0.15, ease: Power3.easeOut })
  }, [className])

  return (
    <StyledCard className={className}>
      <LinkWrapper to={slug}>
        <ImageWrapper>
          <GatsbyImage
            image={image}
            alt={title}
            style={{ height: '100%', width: '100%' }}
            imgStyle={{ objectFit: 'cover' }}
          />
        </ImageWrapper>
      </LinkWrapper>
      <Content>
        <LinkWrapper to={slug}>
          <Title>{title}</Title>
        </LinkWrapper>
        <Preview>{shortDescription}</Preview>
        <LinkWrapper to={slug}>
          <StyledButton>Read More</StyledButton>
        </LinkWrapper>
      </Content>
    </StyledCard>
  )
}

export default BlogCard
