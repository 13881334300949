import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

import { media } from '../utils/style-utils'
import { Table } from '../components'
import { Divider, HomeLink, H1, H5, P } from '../components/shared'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 100%;
  ${media.small`
    padding: 0 20px;
  `};
  ${media.medium`
    width: 100%;
    padding: 0;
  `};
`

const ImageWrapper = styled.div`
  height: 320px;
  ${media.small`
    width: 100%;
  `};
  ${media.large`
    max-width: 1367px; 
  `};
`

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  ${media.large`
    max-width: 1122px;
    margin: 0 auto 83px auto;
  `}
`

const Title = styled(H1)`
  font-family: ${props => props.theme.fonts.boldTitle};
  text-transform: uppercase;
  letter-spacing: 0.02em;
  line-height: 1.42;
  text-align: left;
  width: 100%;
  ${media.small`
    margin: 35px auto 3px auto;
  `}
  ${media.medium`
    margin: 75px auto 10px auto;
    max-width: 990px;
  `}
`

const BottomTitle = styled(Title)`
  ${media.small`
    font-size: 20px;
    margin: 45px auto 16px auto;
  `};
  ${media.medium`
    font-size: 30px;
    margin: 55px auto 36px auto;
  `};
`

const BottomCopy = styled.div`
  ${media.medium`
    max-width: 990px;
    margin: 0 auto;
  `};
`

const DateText = styled(H5)`
  text-align: left;
  width: 100%;
  font-family: ${props => props.theme.fonts.boldTitle};
  color: ${props => props.theme.colors.blogDate};
  text-transform: uppercase;
  letter-spacing: 0.05em;
  line-height: 1.44;
  max-width: 990px;
  ${media.small`
    margin: 0 auto 36px auto;
  `}
  ${media.medium`
    margin: 0 auto 57px auto;
  `}
`

const BackHome = styled.div`
  ${media.small`
    margin: 30px 0;
  `}
  ${media.medium`
    margin: 40px 0;
  `}
`

const ParagraphP = styled(P)`
  line-height: 2.25;
  ${media.small`
    margin: 0 auto 10px auto;
  `};
  ${media.large`
    margin: 0 auto 30px auto;
    max-width: 990px;
  `};
  &:last-of-type {
    margin-bottom: 0;
  }
`

const BottomActions = styled.div`
  ${media.large`
    width: 100%;
    margin: 0 auto 30px auto;
    max-width: 990px;
  `};
`

const RelativeDividerWrapper = styled.div`
  width: 100%;
  position: relative;
  max-width: 990px;
  margin: 0 auto;
`

export const BlogPost = props => {
  const { children, TableRow, EmailBanner, post, tableSpacing, content, contentComponent } = props
  const {
    bannerImage,
    title,
    productsTable,
    bodyTopSection = null,
    bottomTitle,
    amazonButtons,
  } = post

  const PageContent = contentComponent || content

  const d = new Date()
  const month = new Intl.DateTimeFormat('en', { month: 'long' }).format(d)
  const year = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d)

  return (
    <Container>
      {bannerImage && (
        <ImageWrapper>
          <GatsbyImage
            image={getImage(bannerImage)}
            alt={title}
            style={{ height: '100%', width: '100%' }}
            imgStyle={{ objectFit: 'cover' }}
          />
        </ImageWrapper>
      )}
      <ContentContainer>
        <Title>{title}</Title>
        <DateText>
          Updated in {month} {year}
        </DateText>
        <div>
          {bodyTopSection &&
            bodyTopSection.map((paragraph, idx) => (
              <ParagraphP key={`top-paragraph-${idx}`}>{paragraph.copy}</ParagraphP>
            ))}
        </div>
        <RelativeDividerWrapper>
          <Divider maxWidth="990px" />
          {children}
        </RelativeDividerWrapper>
        {productsTable && (
          <>
            <Table
              TableRow={TableRow}
              productsTable={productsTable}
              tableSpacing={tableSpacing}
              amazonButtons={amazonButtons}
            />
            <Divider maxWidth="990px" />
          </>
        )}
        {bottomTitle && content && (
          <>
            <BottomTitle>{bottomTitle}</BottomTitle>
            <BottomCopy>
              <PageContent className="content" content={content} />
            </BottomCopy>
          </>
        )}
        <BottomActions>
          <BackHome>
            <HomeLink />
          </BackHome>
        </BottomActions>
        <EmailBanner />
      </ContentContainer>
    </Container>
  )
}

BlogPost.propTypes = {
  post: PropTypes.object.isRequired,
  TableRow: PropTypes.func.isRequired,
  EmailBanner: PropTypes.func.isRequired,
}

export default BlogPost
