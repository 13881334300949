import React, { useEffect } from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { gsap, Power3 } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger.js'

import { media } from '../../utils/style-utils'
import { Button, TransparentButton } from './buttons'
import { StyledLink, H4, P } from './text'

const JumboTextBox = styled.div`
  visibility: hidden;
  opacity: 0;

  width: 100%;
  min-width: 400px;
  height: fit-content;
  display: flex;
  justify-content: space-around;
  flex-direction: column;

  ${media.small`
    align-items: center;  
    margin: 32px 0 0 0;
    max-width: 415px;
  `}
  ${media.medium`
    margin: 0 auto;
    align-items: flex-start;
    max-width: 650px;
  `}
  ${media.large`
    margin: 0 auto;
    max-width: 1367px;
  `}

`

const Description = styled(H4)`
  font-family: ${props => props.theme.fonts.boldTitle};
  color: ${props => props.textColor || props.theme.colors.light};
  text-transform: uppercase;
  line-height: 1.44;
  letter-spacing: 0.25em;
  ${media.small`
    margin-bottom: 10px;
  `}
  ${media.medium`
    margin-bottom: 19px;
  `}
`

const Divider = styled.div`
  height: 1px;
  width: 100%;
  background-color: ${props => props.textColor || props.theme.colors.light};
  ${media.small`
    max-width: 202px;
  `}
  ${media.medium`
    max-width: 309px;
  `}
`

const Title = styled(StyledLink)`
  font-family: ${props => props.theme.fonts.boldPrimary};
  color: ${props => props.textColor || props.theme.colors.light};
  line-height: 1;
  font-weight: normal;
  margin: 0;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
  ${media.small`
    font-size: 24px;
    padding: 24px 24px 12px 24px;
    text-align: center;
  `}
  ${media.medium`
    font-size: 36px;
    letter-spacing: 0.02em;
    max-width: 650px;
    padding: 26px 0 19px 0;
    text-align: left;
  `}
`

const Subheading = styled(P)`
  color: ${props => props.textColor || props.theme.colors.light};
  font-size: 16px;
  letter-spacing: normal;
  ${media.small`
    padding: 0 27px;
    text-align: center;
    line-height: 1.425;
  `}
  ${media.medium`
    max-width: 650px;
    padding: 0;
    text-align: left;
    line-height: 1.625;
  `}
`

const ButtonLink = styled(Link)`
  text-decoration: none;
  ${media.medium`
    margin-top: 32px;
  `}
`

const TitleGroup = ({ textColor, description, title, subheading, cta, postUrl = '/' }) => {
  const appendClassName = postUrl.replace(/[^a-zA-Z0-9 ]/g, '');
  const classNamePrefix = `JumboTextBox--${appendClassName}`
  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger)
    gsap.to(`.${classNamePrefix}`, { visibility: 'visible', opacity: 1 })
    
    const tl = gsap.timeline({ scrollTrigger: `.${classNamePrefix}`, delay: 0.5 })
    tl.from(`.${classNamePrefix}--divider`, { width: 0 })

    const textEnterConfig = {
      opacity: 0,
      y: 20,
      duration: 0.5,
      ease: Power3.easeOut,
      stagger: 0.15,
    }

    tl.from(`.${classNamePrefix}--description`, textEnterConfig, '<')

    tl.from(`.${classNamePrefix}--copy`, textEnterConfig)
  }, [classNamePrefix])

  return (
    <JumboTextBox className={classNamePrefix}>
      {description && (
        <>
          <Description textColor={textColor} className={`${classNamePrefix}--description`}>
            {description}
          </Description>
          <Divider textColor={textColor} className={`${classNamePrefix}--divider`} />
        </>
      )}
      <Title to={postUrl} textColor={textColor} className={`${classNamePrefix}--copy`}>
        {title}
      </Title>
      <Subheading textColor={textColor} className={`${classNamePrefix}--copy`}>
        {subheading}
      </Subheading>
      <ButtonLink to={postUrl} className={`${classNamePrefix}--copy`}>
        {textColor ? <Button>{cta}</Button> : <TransparentButton>{cta}</TransparentButton>}
      </ButtonLink>
    </JumboTextBox>
  )
}

export default TitleGroup
